<template>
  <div style="text-align: left; font-weight: 700">商品管理</div>
  <div v-if="bargingoodlist.length > 0">
    <div style="text-align: left; margin-top: 30px">已选商品</div>
    <el-table :data="bargingoodShowlist" :key="ran" style="width: 100%">
      <el-table-column type="index" label="#"> </el-table-column>
      <!-- <el-table-column prop="imgUrl" label="商品图片">
        <template #default="scope">
          <img
            :src="imgBaseUrl + scope.row.mediaUrl1"
            alt=""
            style="width: 80px; height: 120px; object-fit: contain"
          />
        </template>
      </el-table-column> -->
      <el-table-column prop="name" label="商品名称"> </el-table-column>
      <el-table-column prop="basePrice" label="商品价格（元）">
      </el-table-column>
      <el-table-column prop="salePrice" label="团购价格（元）">
      </el-table-column>
      <el-table-column prop="upsaleNumber" label="上架数量（件）">
      </el-table-column>
      <el-table-column prop="saledNumber" label="初始销量（件）">
      </el-table-column>
      <el-table-column prop="salePrice" label="状态">
        <template #default="scope">
          <el-tag v-if="scope.row.salesState === 'CANCELLED'" type="info">{{
            scope.row.saleStatus
          }}</el-tag>
          <el-tag
            v-else-if="scope.row.salesState === 'CREATED'"
            type="success"
            >{{ scope.row.saleStatus || "在售" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="content" label="商品简介"> </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <div
            style="display: flex; justify-content: flex-start; flex-wrap: wrap"
          >
            <el-button
              type="text"
              @click="editgood(scope.row)"
              style="margin-right: 10px"
              >编辑</el-button
            >
            <el-button
              type="text"
              style="margin-left: 0px !important"
              @click="downgood(scope.row)"
              >下架</el-button
            >
            <el-button
              type="text"
              style="margin-left: 10px !important"
              @click="deletegood(scope.row)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!--  v-if="bargingoodlist.length > 5" -->
    <el-pagination
      v-if="bargingoodlist.length > 5"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      v-model:currentPage="currentPage"
      :page-sizes="[3, 5, 10]"
      :page-size="pageSize"
      :hide-on-single-page="true"
      layout="sizes, prev, pager, next"
      :total="totalbarginList"
      style="margin-top: 15px; text-align: left"
    >
    </el-pagination>
  </div>
  <div>
    <div style="text-align: left; margin-top: 30px">商品选取</div>
    <el-form
      ref="form"
      :model="teamseachgoodform"
      :inline="true"
      style="
        margin-top: 20px;
        margin-left: 16px;
        display: flex;
        justify-content: flex-start;
      "
      label-width="80px"
    >
      <el-form-item label="商品分类">
        <el-cascader
          v-model="casvalue"
          :options="classOption"
          :props="props"
          @change="handleChange"
          size="small"
          placeholder="请选择或输入！"
          :show-all-levels="false"
          filterable
        ></el-cascader>
      </el-form-item>
    </el-form>
    <!-- 基本信息 -->
    <el-form
      v-if="isshowbase"
      :model="teambaseform"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="商品名称" style="width: 50%; text-align: left">
        <span>{{ waitupGood.name }}</span>
      </el-form-item>
      <el-form-item label="商品分类" style="width: 50%; text-align: left">
        <span>{{ waitupGood.cateName }}</span>
      </el-form-item>
      <el-form-item label="划线价格" style="width: 50%; text-align: left">
        <!-- <div class="displayflex">
        <el-input
          v-model="teambaseform.price"
          placeholder="请输入商品价格！"
          style="width: 150px"
          @change="changetonum"
        ></el-input>
        <span style="margin-left: 15px">元</span>
      </div> -->
        <!-- <div style="text-align: left">
        <span style="color: rgba(0, 0, 0, 0.45)"
          >商品的实际购买金额，最低0.01</span
        >
      </div> -->
        <span>{{ waitupGood.basePrice + "元" }}</span>
      </el-form-item>
      <el-form-item
        label="团购价格"
        class="flexcol"
        prop="salePrice"
        size="small"
        style="width: 50%"
      >
        <div class="flexrow">
          <el-input
            v-model="teambaseform.salePrice"
            clearable
            placeholder="请输入上架价格"
            style="width: 140px; text-align: left"
          ></el-input>
          <span style="margin-left: 5px">元</span>
        </div>
        <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
          >上架价格仅用于电商页展示
        </span>
      </el-form-item>
      <el-form-item
        class="flexcol"
        label="库存"
        size="small"
        prop="upsaleNumber"
        style="width: 50%"
      >
        <div class="flexrow">
          <el-input
            v-model="teambaseform.upsaleNumber"
            clearable
            placeholder="请输入库存"
            style="width: 140px; text-align: left"
          ></el-input>
          <span style="margin-left: 10px">件</span>
        </div>
        <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
          >库存余量仅用于商品页展示
        </span>
      </el-form-item>
      <el-form-item
        label="初始销量"
        class="flexcol"
        prop="saledNumber"
        size="small"
        style="width: 50%"
      >
        <div class="flexrow">
          <el-input
            v-model="teambaseform.saledNumber"
            clearable
            placeholder="请输入初始销量"
            style="width: 140px; text-align: left"
          ></el-input>
          <span style="margin-left: 5px">件</span>
        </div>

        <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
          >用户端展示的销量 = 初始销量 + 实际销量
        </span>
      </el-form-item>
      <el-form-item style="display: flex">
        <el-button
          size="mini"
          type="primary"
          @click="nextformshow"
          v-show="!proformshow"
          >下一步</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 商品信息 -->
    <el-form
      v-if="proformshow"
      :model="waitupGood"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="商品图片" class="goodpic" style="width: 50%">
        <el-upload
          :action="baseUrl + '/nktdec/uploader/uploadfile'"
          list-type="picture-card"
          style="display: flex; justify-content: flex-start"
          :auto-upload="false"
          accept=".mp4,.jpg,.jpeg,.png"
          :on-change="filechange"
          :file-list="productpciList"
          :limit="8"
          :on-exceed="handleexceed"
        >
          <template #default>
            <i class="el-icon-plus"></i>
          </template>
          <template #file="{ file }">
            <div style="width: 100%; height: 100%">
              <video
                style="width: 100%; height: 100%"
                v-if="file.raw && file.raw.type === 'video/mp4'"
                :src="file.url"
              ></video>
              <img
                v-else
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <!-- <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </template>
        </el-upload>
        <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
          >建议宽高比1：1, 最多上传8张, 第1张将作为商品首图</span
        >
      </el-form-item>

      <el-form-item label="商品简介" prop="content" style="width: 50%">
        <el-input
          v-model="waitupGood.content"
          clearable
          placeholder="请输入商品得简要描述！"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品描述" prop="description" style="width: 80%">
        <vue-ueditor-wrap
          v-model="waitupGood.description"
          :config="editorConfig"
          editor-id="upgoodinfo"
        ></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item style="display: flex">
        <el-button size="mini" type="primary" @click="nextgood"
          >下一商品</el-button
        >
        <el-button size="mini" type="primary" @click="onsubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  data() {
    return {
      teamseachgoodform: {
        productName: ''
      },
      classOption: [],
      casvalue: [],
      props: {
        value: 'id',
        label: 'name'
      },
      teambaseform: {
        salePrice: '',
        upsaleNumber: '',
        saledNumber: '',
      },
      rules: {
        salePrice: [
          { required: true, message: '请输入上架价格！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        upsaleNumber: [
          { required: true, message: '请输入上架数量！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      isaddupgood: true,
      proTotalPage: 0,
      waitupGood: {},
      isshowbase: false,
      proformshow: false,
      productpciList: [],
      baseUrl: '',
      bargingoodlist: [],
      bargingoodShowlist: [],
      imgBaseUrl: '',
      totalbarginList: 0,
      pageSize: 5,
      currentPage: 1,
      ran: 0,
      submitpicList: []
    }
  },
  computed: {
    changeUPGoodsInfo() {
      return this.$store.state.changeUPGoodsInfo
    },
    editorConfig() {
      return this.$store.state.editorConfig
    }
  },
  created() {
    const Arr = [
      {
        name: '首页',
        index: '/index'
      },
      {
        name: '活动管理',
        index: '/teammange'
      },
      {
        name: '团购管理',
        index: '/teammange'
      }, {
        name: '商品管理',
        index: ''
      }]
    this.$store.commit('setBreadInfo', Arr)
    this.getbarginList()
    this.baseUrl = this.$http.defaults.baseURL
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'
    setTimeout(() => {
      this.getClassOPtion()
    }, 0)
    if (this.$route.params.isadd === 'false') {
      this.isaddupgood = false
      this.isshowbase = true
      this.waitupGood = this.changeUPGoodsInfo
      this.teamseachgoodform.productName = this.waitupGood.name
      this.teambaseform.salePrice = this.waitupGood.salePrice
      this.teambaseform.upsaleNumber = this.waitupGood.upsaleNumber
      this.teambaseform.saledNumber = this.waitupGood.saledNumber
      this.getimgurl()
      this.proformshow = true
      console.log(this.submitpicList)
    }
  },
  methods: {
    handleRemove(file) {
      console.log('提交数组', this.submitpicList)
      var i = this.productpciList.findIndex(item => item.url === file.url)
      // var arr = this.cousepicList.filter(item => item.url !== file.url)
      // this.cousepicList = arr
      console.log(i)
      console.log(this.productpciList)
      // if (i !== -1) {
      this.productpciList.splice(i, 1)
      // }
      console.log(file.url.split('/'))

      // this.waitupGood['mediaUrl'+i]=''
      var tmpstrArr = file.url.split('/')
      var j = this.submitpicList.findIndex(item => item === tmpstrArr[tmpstrArr.length - 1])
      // this.waitupGood[keys[j]] = ''
      this.submitpicList.splice(j, 1)
      console.log('提交数组', this.submitpicList)
      console.log('绑定数组', this.productpciList)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.bargingoodShowlist = []
      this.ran = Math.random()
      for (let i = 0; i < val; i++) {
        if (this.bargingoodlist[i]) {
          this.bargingoodShowlist.push(this.bargingoodlist[i])
          this.ran = Math.random()
        }
      }
    },
    handleCurrentChange(val) {
      this.bargingoodShowlist = []
      for (let i = this.pageSize * (val - 1); i < this.pageSize * (val); i++) {
        if (this.bargingoodlist[i]) {
          this.bargingoodShowlist.push(this.bargingoodlist[i])
          this.ran = Math.random()
        }
      }
    },
    async getbarginList() {
      const res = await this.$http.get('/nktdec/salesactivity/salesactivity/' + this.$route.params.actId)
      console.log(res.data)
      this.bargingoodlist = res.data.bargains
      this.bargingoodShowlist = []
      this.submitpicList = []
      for (let i = 0; i < this.pageSize; i++) {
        if (this.bargingoodlist[i]) {
          this.bargingoodShowlist.push(this.bargingoodlist[i])
          this.ran = Math.random()
        }
      }
      this.totalbarginList = res.data.bargains.length
      // this.pageSize = res.data.bargains.length
    },
    async getallproInfo(item, caid) {
      this.getprooption(item, 1, caid)
      if (this.proTotalPage > 1) {
        for (let i = 2; i < this.proTotalPage; i++) {
          this.getprooption(item, i, caid)
        }
      }
    },
    async getprooption(item, pageno, caid) {
      const res = await this.$http.get('/nktdec/productinfo/productinfos/' + pageno + '?categoryId=' + caid)
      item.children = []
      // console.log(item)

      res.data.content.forEach(resitem => {
        item.children.push(resitem)
      })
      this.proTotalPage = res.data.totalPages
    },
    async getClassOPtion() {
      const res = await this.$http.get('/nktdec/categoryinfo/categoryinfos?type=PRODUCT')
      this.classOption = res.data
      this.getClassOPtionchildren()
    },
    getClassOPtionchildren() {
      this.classOption.forEach(item => {
        this.getallproInfo(item, item.id)
      })
    },
    handleChange(value) {
      // console.log(value)
      var tmpArr = this.classOption.filter(item => item.id === value[0])
      this.isshowbase = true
      this.waitupGood = tmpArr[0].children.filter(itemitem => itemitem.id === value[1])[0]
      this.waitupGood.cateName = tmpArr[0].name
      console.log(this.waitupGood)
      this.getimgurl()
    },

    async getimgurl() {
      this.productpciList = []
      for (let i = 1; i <= 8; i++) {
        if (this.waitupGood.id && this.waitupGood['mediaUrl' + i].trim() !== '') {
          this.productpciList.push({
            url: this.imgBaseUrl + this.waitupGood['mediaUrl' + i]
          })
          this.submitpicList.push(this.waitupGood['mediaUrl' + i])
        } else {
          break
        }
      }
    },
    handleexceed() {
      this.$message.error('仅限上传8个媒体文件！')
    },
    async filechange(file, fileList) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      this.productpciList.push({
        url: this.imgBaseUrl + res.data.result
      })
      console.log(res)
      this.submitpicList.push(res.data.result)
      console.log(this.submitpicList)
    },
    nextformshow() {
      const val = Object.values(this.teambaseform)
      console.log(val)

      if (val.some(item => (item + '').trim() === '')) {
        this.$message.error('请将信息填写完整！')
        return
      }
      this.proformshow = true
    },
    async pushbargin() {
      console.log(this.submitpicList)
      if (this.submitpicList[0].trim() === '') {
        this.$message.error('请上传图片！')
        return
      }
      // console.log(this.waitupGood)
      const tmpArr = _.cloneDeep(this.waitupGood)
      const basetmpArr = _.cloneDeep(this.teambaseform)
      const res = await this.$http.get('/nktdec/uploader/images/' + tmpArr.mediaUrl1)
      // console.log(basetmpArr)
      // console.log(this.bargingoodlist)
      // console.log(!(this.bargingoodlist.some(item => item.productId === tmpArr.productId)))
      if (!(this.bargingoodlist.some(item => item.productId === tmpArr.productId))) {
        this.isshowbase = false
        this.proformshow = false
        this.teamseachgoodform.productName = ''
        this.casvalue = ''
        const data = {}
        data.productId = tmpArr.id
        data.activityId = this.$route.params.actId
        data.name = tmpArr.name
        data.content = tmpArr.content
        data.description = tmpArr.description
        data.mediaUrl1 = this.submitpicList[0]
        data.mediaUrl2 = this.submitpicList[1] ? this.submitpicList[1] : ''
        data.mediaUrl3 = this.submitpicList[2] ? this.submitpicList[2] : ''
        data.mediaUrl4 = this.submitpicList[3] ? this.submitpicList[3] : ''
        data.mediaUrl5 = this.submitpicList[4] ? this.submitpicList[4] : ''
        data.mediaUrl6 = this.submitpicList[5] ? this.submitpicList[5] : ''
        data.mediaUrl7 = this.submitpicList[6] ? this.submitpicList[6] : ''
        data.mediaUrl8 = this.submitpicList[7] ? this.submitpicList[7] : ''
        data.basePrice = tmpArr.basePrice
        data.salePrice = basetmpArr.salePrice - 0
        data.upsaleNumber = basetmpArr.upsaleNumber - 0
        data.saledNumber = basetmpArr.saledNumber - 0
        data.imgUrl = this.baseUrl + res.config.url.substring(1)
        console.log('提交数据', data)
        const result = await this.$http.post('/nktdec/bargain/bargains', data)
        console.log(result)
        this.getbarginList()
      } else {
        console.log('判断进入后边条件')
        this.isshowbase = false
        this.proformshow = false
        this.casvalue = ''
        this.teamseachgoodform.productName = ''
        var i = this.bargingoodlist.findIndex(item => item.productId === tmpArr.productId)
        const data = {}
        data.productId = tmpArr.productId
        data.id = tmpArr.id
        data.activityId = this.$route.params.actId
        data.name = tmpArr.name
        data.content = tmpArr.content
        data.description = tmpArr.description
        data.mediaUrl1 = this.submitpicList[0]
        data.mediaUrl2 = this.submitpicList[1] ? this.submitpicList[1] : ''
        data.mediaUrl3 = this.submitpicList[2] ? this.submitpicList[2] : ''
        data.mediaUrl4 = this.submitpicList[3] ? this.submitpicList[3] : ''
        data.mediaUrl5 = this.submitpicList[4] ? this.submitpicList[4] : ''
        data.mediaUrl6 = this.submitpicList[5] ? this.submitpicList[5] : ''
        data.mediaUrl7 = this.submitpicList[6] ? this.submitpicList[6] : ''
        data.mediaUrl8 = this.submitpicList[7] ? this.submitpicList[7] : ''
        data.basePrice = tmpArr.basePrice
        data.salePrice = basetmpArr.salePrice - 0
        data.upsaleNumber = basetmpArr.upsaleNumber - 0
        data.saledNumber = basetmpArr.saledNumber - 0
        data.imgUrl = this.baseUrl + res.config.url.substring(1)
        console.log('提交数据', this.submitpicList, data)
        console.log(i)
        const result = await this.$http.post('/nktdec/bargain/bargains', data)
        console.log(result)
        this.getbarginList()
        // this.bargingoodlist.splice(i, 1, data)
        // this.initForm()
        // return false
      }
    },
    async nextgood() {
      this.pushbargin()
    },
    initForm() {
      this.teambaseform.salePrice = ''
      this.teambaseform.upsaleNumber = ''
      this.teambaseform.saledNumber = ''
    },
    async onsubmit() {
      await this.pushbargin()
      // console.log(this.bargingoodlist)
      // const tmparr = _.cloneDeep(this.bargingoodlist)
      // tmparr.forEach(async (item) => {
      //   delete item.imgUrl
      //   const res = await this.$http.post('/nktdec/bargain/bargains', item)
      //   console.log(res)
      // })
      this.$router.push('/teammange')
    },
    deletegood(row) {
      console.log(row)
      this.$confirm('此操作将删除此商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete('/nktdec/bargain/bargain/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('删除成功！')
          this.getbarginList()
        }
      })
    },
    downgood(row) {
      console.log(row)
      this.$confirm('此操作将下架此商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.put('/nktdec/bargain/bargain/' + row.id)
        console.log(res)
        if (res.data.code === '1') {
          this.$message.success('下架成功！')
          this.getbarginList()
        }
        // var i = this.bargingoodlist.findIndex(item => item.productId === row.productId)
        // this.bargingoodlist.splice(i, 1)
      })
    },
    editgood(row) {
      console.log(row)
      this.casvalue = [row.categoryId, row.productId]
      this.submitpicList = []
      this.productpciList = []
      if (!this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl1)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl1
        })
      }
      if (row.mediaUrl2.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl2)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl2
        })
      }
      if (row.mediaUrl3.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl3)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl3
        })
      }
      if (row.mediaUrl4.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl4)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl4
        })
      }
      if (row.mediaUrl5.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl5)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl5
        })
      }

      if (row.mediaUrl6.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl6)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl6
        })
      }
      if (row.mediaUrl7.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl7)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl7
        })
      }
      if (row.mediaUrl8.trim() !== '' && !this.productpciList.some(item => item.url === this.imgBaseUrl + row.mediaUrl8)) {
        this.productpciList.push({
          url: this.imgBaseUrl + row.mediaUrl8
        })
      }
      console.log('照片列表', this.productpciList)
      this.teamseachgoodform.productName = row.name
      this.teambaseform.salePrice = row.salePrice
      this.teambaseform.upsaleNumber = row.upsaleNumber
      this.teambaseform.saledNumber = row.saledNumber
      this.waitupGood = row
      this.submitpicList = []
      for (let i = 1; i <= 8; i++) {
        if (this.waitupGood['mediaUrl' + i].trim() !== '') {
          this.submitpicList.push(this.waitupGood['mediaUrl' + i])
        }
      }
      this.waitupGood.cateName = this.classOption.find(item => item.id === row.categoryId).name

      this.isshowbase = true
      this.proformshow = true
      console.log('提交数组', this.submitpicList)
    }
  },
}
</script>
<style scoped>
</style>
