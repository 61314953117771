<template>
  <div style="text-align: left">编辑直播活动</div>
  <el-form
    :model="addLiveForm"
    :rules="rules"
    ref="ruleForm"
    label-width="100px"
    style="margin-top: 30px"
    class="demo-ruleForm"
  >
    <el-form-item
      label="直播主题"
      prop="caption"
      size="small"
      style="width: 50%"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="addLiveForm.caption"
          placeholder="请输入直播主题"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="直播房间号"
      size="small"
      prop="roomNo"
      style="width: 50%; text-align: left"
    >
      <div style="display: flex">
        <el-input
          style="width: 230px"
          clearable
          v-model="addLiveForm.roomNo"
          placeholder="请输入直播房间号"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item label="团购期数" prop="caption" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addTeamForm.issue"
          clearable
          style="width: 230px"
          placeholder="请输入团购期数"
        ></el-input>
      </div>
    </el-form-item>
      <el-form-item label="团购标题" prop="caption" style="width: 50%">
      <div style="display: flex">
        <el-input
          v-model="addTeamForm.subject"
          clearable
          style="width: 230px"
          placeholder="请输入团购主题"
        ></el-input>
      </div>
    </el-form-item>
    <el-form-item
      label="直播时间"
      size="small"
      prop="teamTimeproid"
      style="width: 50%; text-align: left"
    >
      <el-date-picker
        v-model="addLiveForm.teamTimeproid"
        type="datetimerange"
        :shortcuts="shortcuts"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item
      label="直播图片"
      prop="goodsPic"
      class="goodpic"
      style="width: 50%"
    >
      <el-upload
        action="#"
        list-type="picture-card"
        style="display: flex; justify-content: flex-start"
        :auto-upload="false"
        accept=""
        :file-list="teamlist"
        :on-change="filechange"
        :limit="1"
        :on-exceed="handleexceed"
      >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <span style="color: rgba(0, 0, 0, 0.45); text-align: left"
        >建议宽高比1：1, 只可上传一张图片</span
      >
    </el-form-item>

    <!-- <el-form-item
      label="活动状态"
      prop="goodInstan"
      style="width: 50%; text-align: left"
    >
      <el-radio-group v-model="addLiveForm.teamStatus">
        <el-radio label="up">开始</el-radio>
        <el-radio label="down">结束</el-radio>
      </el-radio-group>
    </el-form-item> -->
    <!-- <el-form-item
      label="商品选择"
      prop="teamGoods"
      style="width: 50%; text-align: left"
    >
      <el-cascader
        v-model="addLiveForm.teamGoods"
        @change="log"
        :options="options"
        :props="{ multiple: true }"
        clearable
      ></el-cascader>
    </el-form-item> -->
  </el-form>
  <div class="displayflex">
    <el-button
      type="primary"
      size="mini"
      style="margin-left: 30px"
      @click="onsubmit"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      addLiveForm: {
        caption: '',
        type: 'LIVE',
        coverUrl: '',
        teamTimeproid: [],
        startDate: '',
        endDate: '',
        roomNo: '',
        subject: '',
        issue: ''
      },
      rules: {
        caption: [
          { required: true, message: '请输入直播主题！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        roomNo: [
          { required: true, message: '请输入直播房间号！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        teamTimeproid: [
          { required: true, message: '请选取直播活动时间！', trigger: 'blur' }
          //   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      baseUrl: '',
      teamlist: [],
      shortcuts: [{
        text: '最近一周',
        value: (() => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() + 24 * 60 * 60 * 1000)
          end.setTime(start.getTime() + 7 * 24 * 60 * 60 * 1000)
          // start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        })()
      }],
      imgBaseUrl: ''
    }
  },
  methods: {
    handleexceed() {
      this.$message.error('仅限上传1个媒体文件！')
    },
    handleRemove(file) {
      this.teamlist = []
      this.addLiveForm.coverUrl = ''
    },
    // handlePictureCardPreview(file) {
    //   this.dialogImageUrl = file.url
    // },
    async onsubmit() {
      console.log(this.teamlist)
      if (this.addLiveForm.coverUrl.trim() === '') {
        this.$message.error('请上传图片！')
        return
      } const data = {}
      if (this.$route.params.isadd === 'false') {
        data.id = this.addLiveForm.id
        data.caption = this.addLiveForm.caption
        data.roomNo = this.addLiveForm.roomNo
        data.type = 'LIVE'
        data.coverUrl = this.addLiveForm.coverUrl
        data.startDate = this.formatdate(this.addLiveForm.teamTimeproid[0])
        data.endDate = this.formatdate(this.addLiveForm.teamTimeproid[1])
        data.issue = this.addTeamForm.issue
        data.subject = this.addTeamForm.subject
      } else {
        data.caption = this.addLiveForm.caption
        data.type = 'LIVE'
        data.coverUrl = this.addLiveForm.coverUrl
        data.roomNo = this.addLiveForm.roomNo
        data.startDate = this.formatdate(this.addLiveForm.teamTimeproid[0])
        data.endDate = this.formatdate(this.addLiveForm.teamTimeproid[1])
        data.issue = this.addTeamForm.issue
        data.subject = this.addTeamForm.subject
      }
      console.log(data)
      const res = await this.$http.post('/nktdec/salesactivity/salesactivities', data)
      console.log(res)
      if (res.data.code === '1') {
        this.$message.success('直播设置成功！')
        this.$router.push('/liveinfo')
      }
    },
    formatdate(date) {
      var datestr = date.toLocaleDateString().split('/').join('-')
      var str = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      str += ':'
      str += date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      str += ':'
      str += date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return datestr + ' ' + str
    },
    async filechange(file, fileList) {
      const formData = new FormData()
      formData.append('file', file.raw)
      formData.append('params', '参数')
      const res = await this.$http(
        {
          method: 'post',
          url: '/nktdec/uploader/uploadfile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data;',
          }
        }
      )
      console.log(res)
      this.teamlist.push({
        url: this.imgBaseUrl + res.data.result
      })
      // const coverurl = await this.$http.get('/nktdec/uploader/images/' + res.data.result)
      // console.log(this.baseUrl + coverurl.config.url.substring(1))
      this.addLiveForm.coverUrl = res.data.result
    },
  },
  computed: {
    TeamWorkAc() {
      return this.$store.state.TeamWorkAc
    },
  },
  created() {
    this.baseUrl = this.$http.defaults.baseURL
    // this.imgBaseUrl = this.$http.defaults.baseURL + 'nktdec/uploader/images/'
    this.imgBaseUrl = 'http://nktdec.ctvideo.cn/images/'

    if (this.$route.params.isadd === 'false') {
      this.addLiveForm = this.TeamWorkAc
      this.addLiveForm.teamTimeproid = []
      this.addLiveForm.teamTimeproid.push(new Date(this.addLiveForm.startDate))
      this.addLiveForm.teamTimeproid.push(new Date(this.addLiveForm.endDate))
      console.log(this.addLiveForm)
      this.teamlist.push({
        url: this.imgBaseUrl + this.addLiveForm.coverUrl
      })
      console.log(this.teamlist)
    }
  },
}
</script>
<style scoped>
</style>
